import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(3);

    // Обновление количества слайдов для отображения в зависимости от ширины экрана
    useEffect(() => {
        const updateSlidesToShow = () => {
            if (window.innerWidth < 400) {
                setSlidesToShow(1);
            } else if (window.innerWidth < 992) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        };
        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);
        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };

    const isPrevButtonDisabled = currentSlide === 0;
    const isNextButtonDisabled = currentSlide === images.length - slidesToShow;

    return (
        <div className="carousel-container">
            <button
                className="prev-button"
                onClick={handlePrevSlide}
                disabled={isPrevButtonDisabled}
            >
                &lt;
            </button>
            <div className="carousel">
                {images.slice(currentSlide, currentSlide + slidesToShow).map((image, index) => (
                    <div key={index} className="carousel-item">
                        <img src={image.url} alt={image.caption} />
                        <p className="caption">{image.caption}</p>
                    </div>
                ))}
            </div>
            <button
                className="next-button"
                onClick={handleNextSlide}
                disabled={isNextButtonDisabled}
            >
                &gt;
            </button>
        </div>
    );
};

export default ImageCarousel;
