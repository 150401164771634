import React, {useEffect, useState} from 'react';
import Fullpage, {FullPageSections, FullpageSection, FullpageNavigation} from '@ap.cx/react-fullpage';
import bg1 from './img/bg1.jpg';
import bg2 from './img/bg2.jpg';
import bg3 from './img/bg3.jpg';
import './FullPage.css'
import image1 from './img/VSP/1.png';
import image2 from './img/VSP/2.png';
import image3 from './img/VSP/3.png';
import image4 from './img/VSP/4.png';
import image5 from './img/VSP/5.png';
import image6 from './img/VSP/6.png';
import image7 from './img/VSP/7.png';
import image8 from './img/VSP/8.png';
import image9 from './img/VSP/9.png';
import image10 from './img/VSP/10.png';
import image11 from './img/VSP/11.png';
import image12 from './img/VSP/12.png';

import tool1 from './img/tools/1.png';
import tool2 from './img/tools/2.png';
import tool3 from './img/tools/3.png';
import tool4 from './img/tools/4.png';
import tool5 from './img/tools/5.png';
import tool6 from './img/tools/6.png';
import tool7 from './img/tools/7.png';
import tool8 from './img/tools/8.png';
import tool9 from './img/tools/9.png';
import tool10 from './img/tools/10.png';
import tool11 from './img/tools/11.png';
import tool12 from './img/tools/12.png';
import tool13 from './img/tools/13.png';
import tool14 from './img/tools/14.png';
import tool15 from './img/tools/15.png';
import tool16 from './img/tools/16.png';
import ImageSlider from "./ImageSlider";

const FullPageScroll = () => {
    const images = [
        {
            url: image1,
            caption: 'Болты путевые, клемма ПК'
        },
        {
            url: image2,
            caption: 'ЖБИ для железнодорожного строительства'
        },
        {
            url: image3,
            caption: 'Изоляция пути'
        },
        {
            url: image4,
            caption: 'Костыль железнодорожный, шуруп путевой'
        },
        {
            url: image5,
            caption: 'Крепление крановых рельс'
        },
        {
            url: image6,
            caption: 'Накладки рельсовые'
        },
        {
            url: image7,
            caption: 'Подкладки рельсовые'
        },
        {
            url: image8,
            caption: 'Противоугоны'
        },
        {
            url: image9,
            caption: 'Рельсы'
        },
        {
            url: image10,
            caption: 'Стрелочные переводы'
        },
        {
            url: image11,
            caption: 'Скрепления АРС'
        },
        {
            url: image12,
            caption: 'Шпалы, брус для стрелочных переводов'
        },
    ];
    const tools = [
        {
            url: tool1,
            caption: 'Гайковёрты и шуруповёрты'
        },
        {
            url: tool2,
            caption: 'Генераторы, сварочные агрегаты и трансформаторы'
        },
        {
            url: tool3,
            caption: 'Домкраты, рихтовщики, разгонщики стыков'
        },
        {
            url: tool4,
            caption: 'Дополнительные приспособления'
        },
        {
            url: tool5,
            caption: 'Захваты рельсовые'
        },
        {
            url: tool6,
            caption: 'Измерительный инструмент'
        },
        {
            url: tool7,
            caption: 'Инструмент для работы со шпаламии балластом'
        },
        {
            url: tool8,
            caption: 'Инструмент для уборки'
        },
        {
            url: tool9,
            caption: 'Осветительное оборудование'
        },
        {
            url: tool10,
            caption: 'Расходные материалы'
        },
        {
            url: tool11,
            caption: 'Рельсорезные станки'
        },
        {
            url: tool12,
            caption: 'Рельсосверильные станки и фаскосъемники'
        },
        {
            url: tool13,
            caption: 'Ручной путевой инструмент'
        },
        {
            url: tool14,
            caption: 'Сигнальные знаки'
        },
        {
            url: tool15,
            caption: 'Транспортные устройства'
        },
        {
            url: tool16,
            caption: 'Шлифовальные станки'
        },
    ];

    const [sectionStyles, setSectionStyles] = useState({
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: null,
        backgroundPosition: 'center',
    });

    useEffect(() => {
        const handleScreenSize = () => {
            if (window.innerWidth > 889) {
                // noinspection JSCheckFunctionSignatures
                setSectionStyles({...sectionStyles, backgroundSize: '100% 100%'});
            } else {
                // noinspection JSCheckFunctionSignatures
                setSectionStyles({...sectionStyles, backgroundSize: 'auto 100%'});
            }
        };

        handleScreenSize();
        window.addEventListener('resize', handleScreenSize); // Добавляем обработчик события resize

        // Очистка обработчика события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleScreenSize)
        };
    }, []);

    return (
        <Fullpage>
            <FullpageNavigation/>
            <FullPageSections>
                <FullpageSection className='section1' style={{...sectionStyles, backgroundImage: `url(${bg1})`}}>
                    <h1>ООО "МИТАР-МЕТ"</h1>
                    <p>
                        ООО «МИТАР-МЕТ» - лидер в производстве и продаже широкого ассортимента путевого
                        инструмента и материалов ВСП. <br/>
                        Мы представляем оптимальные решения для проведения строительных и ремонтных работ. <br/>
                        Наши специалисты готовы помочь Вам с выбором и обеспечить профессиональное обслуживание. <br/>
                        Присоединяйтесь к тысячам довольных клиентов, которые уже выбрали нас в качестве надежного
                        партнера для решения своих строительных задач!
                    </p>
                </FullpageSection>
                <FullpageSection className='section2' style={{...sectionStyles, backgroundImage: `url(${bg2})`}}>
                    <div className="items1">
                        <h2>Материалы ВСП <br/>(верхнего строения пути)</h2>
                        <ImageSlider images={images}></ImageSlider>
                    </div>
                   <div className="items2">
                       <h2>Путевой инструмент</h2>
                       <ImageSlider images={tools}></ImageSlider>
                   </div>
                </FullpageSection>
                <FullpageSection className='section3' style={{...sectionStyles, backgroundImage: `url(${bg3})`}}>
                    <h2 className='contacts'>Контакты</h2>
                    <div className="contact-block">
                        <div className="contact-card company">
                            <div className="background"></div>
                            <p>ООО "МИТАР-МЕТ"</p>
                            <p>
                                г. Витебск, пр-т Черняховского,<br/>
                                д. 33, пом. 6
                            </p>
                            <p>
                                Тел./факс: +375 212 67 96 56 <br/>
                                Телефон: +375 212 67 96 57
                            </p>
                            <p>
                                Эл. почта: mitar-met@mail.ru
                            </p>
                        </div>
                        <div className="contact-card director">
                            <div className="background"></div>
                            <p>
                                Контактное лицо: <br/>
                                Константин Викторович
                                </p>
                            <p>
                                +375 29 637 14 39 <br/>
                                +375 212 62 42 39
                            </p>
                            <p>
                                +7 499 348 86 36 <br/>
                                +7 499 380 84 05
                            </p>
                        </div>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
};

export default FullPageScroll;
